<template>
  <div
    ref="rootElement"
    class="color-picker"
  >
    <div
      class="color-picker__input"
      @click="toggleMenu"
    >
      <i
        class="color-picker__input-preview"
        :style="{ 'background-color': modelValue }"
      />
      <input
        ref="inputFieldElement"
        class="color-picker__input-field"
        type="text"
        readonly
        :value="modelValue"
        :placeholder="placeholder"
      >
      <button
        v-if="modelValue"
        class="color-picker__input-clear"
        @click.stop.prevent="clearModelValue"
      >
        <Icon
          name="x"
        />
      </button>
      <button
        v-else
        class="color-picker__input-chevron"
      >
        <Icon
          name="chevron-selector"
        />
      </button>
    </div>
    <div
      v-if="isMenuToggled"
      class="color-picker__dropdown"
    >
      <Vue3ColorPicker
        v-model="internalModelValue"
        type="HEX8"
        input-type="HEX8"
        mode="solid"
        :show-color-list="false"
        :show-eye-drop="false"
        :show-alpha="false"
        :show-input-menu="false"
        :show-input-set="false"
      />
      <div class="color-picker__input">
        <i
          class="color-picker__input-preview"
          :style="{ 'background-color': internalModelValue }"
        />
        <input
          class="color-picker__input-field"
          type="text"
          :value="internalModelValue"
          @input="internalModelValue = $event.target.value"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
    Vue3ColorPicker,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select color',
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref();
    const inputFieldElement = ref();
    const internalModelValue = ref(props.modelValue);
    const isMenuToggled = ref(false);

    const toggleMenu = () => {
      isMenuToggled.value = !isMenuToggled.value;
    };
    const updateModelValue = (newModelValue) => {
      if (props.modelValue === newModelValue) return;
      internalModelValue.value = newModelValue;
      emit('update:modelValue', newModelValue);
    };
    const clearModelValue = () => {
      updateModelValue('');
    };

    onClickOutside(rootElement, () => {
      updateModelValue(internalModelValue.value);
      isMenuToggled.value = false;
    });

    return {
      rootElement,
      inputFieldElement,
      internalModelValue,
      isMenuToggled,
      toggleMenu,
      updateModelValue,
      clearModelValue,
    };
  },
};
</script>

<style lang="scss">
.color-picker {
  position: relative;
  width: 100%;

  &__input {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    &-preview {
      position: absolute;
      left: 8px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      border: 1px solid #000;
    }

    &-field {
      background-color: #fff;
      border: 1px solid #F0F0F0;
      padding: 8px calc(8px + 4px) 8px calc(8px + 16px + 4px);
      width: 100%;
      height: 32px;
      border-radius: 4px;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        outline: 1px solid #a2bfff;
      }

      &:read-only {
        padding: 8px calc(8px + 16px + 4px) 8px calc(8px + 16px + 4px);
        cursor: pointer;
      }
    }

    &-clear {
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      stroke: #000;
    }

    &-chevron {
      position: absolute;
      right: 8px;
      width: 16px;
      height: 16px;
      pointer-events: none;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 16px;
    user-select: none;

    .ck-cp-container {
      box-shadow: none;
      padding: 0;
    }
  }
}
</style>
