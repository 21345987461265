<template>
  <div
    :class="[
      'tooltip',
      { 'tooltip--right': !left && !top && !bottom },
      { 'tooltip--left': left },
      { 'tooltip--top': top },
      { 'tooltip--bottom': bottom },
      { 'is-active': isVisible },
    ]"
    @mouseover="displayTooltip"
    @mouseleave="hideTooltip"
  >
    <div class="tooltip-element">
      <slot />
    </div>

    <div
      v-show="isVisible"
      class="tooltip-text"
    >
      {{ text }}
    </div>

    <span
      v-show="isVisible"
      class="tooltip-text-arrow"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isVisible = ref(false);
    const displayTooltip = () => {
      if (props.hide || isVisible.value) return;
      isVisible.value = true;
    };
    const hideTooltip = () => {
      if (!isVisible.value) return;
      isVisible.value = false;
    };

    watch(() => props.hide, (newHide) => {
      if (!newHide) return;
      isVisible.value = false;
    });

    return {
      isVisible,
      displayTooltip,
      hideTooltip,
    };
  },
};
</script>

<style lang="scss">
.tooltip {
  position: unset;
  width: auto;
  height: auto;
  z-index: 100;
  user-select: none;

  &.is-active {
    position: relative;
    z-index: 101;
  }

  .tooltip-element {
    width: 100%;
    height: 100%;
  }

  .tooltip-text {
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 12px;
    background-color: $black;
    color: $white;
    white-space: nowrap;
    padding: 0 12px;
    height: 38px;
    border-radius: 8px;
    z-index: 100;
  }

  .tooltip-text-arrow {
    background-color: $black;
    position: absolute;
    width: 6px;
    height: 6px;
  }
  /** LEFT & RIGHT */
  &.tooltip--right,
  &.tooltip--left {
    .tooltip-text,
    .tooltip-text-arrow{
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.tooltip--right {
    .tooltip-text {
      left: calc(100% + 6px + 10px);
    }

    .tooltip-text-arrow {
      left: calc(100% + 10px);
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }
  &.tooltip--left {
    .tooltip-text {
      right: calc(100% + 6px + 10px);
    }

    .tooltip-text-arrow {
      right: calc(100% + 10px);
      clip-path: polygon(100% 50%, 0 0, 0 100%);
    }
  }

  /** TOP & BOTTOM */
  &.tooltip--top,
  &.tooltip--bottom {
    .tooltip-text,
    .tooltip-text-arrow{
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.tooltip--top {
    .tooltip-text {
      bottom: calc(100% + 10px);
    }

    .tooltip-text-arrow {
      bottom: calc(100% + 4px);
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
  &.tooltip--bottom {
    .tooltip-text {
      top: calc(100% + 10px);
    }

    .tooltip-text-arrow {
      top: calc(100% + 4px);
      clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
  }
}
</style>
